declare interface Window {
    suggest2017:any
}
if (window.suggest2017) {
    //@ts-ignore
    let newsuggest = new suggest2017({ //新建实例
        inputid: 'code_suggest', //参数部分
        shownewtips: true, //参数部分
        newtipsoffset: { left: -8, top: -4 }, //参数部分
        placeholder: '输入股票代码、名称、简拼或关键字', //参数部分
        offset: { left: 0, top: 0 }
    })  
}


$('#ArticleRankTab>li').hover(function () {
  var index = $(this).index() + 1 + 0;
  $(this).addClass('at').siblings().removeClass('at');
  $('#NewsRank' + index).show().siblings().hide();
})



let isLoading:Boolean = false;

let timer:any = '';
function startTimer() {
    timer = setTimeout(function () {
        getListData();
        startTimer();
    }, 60000);
}
function getListData() {
    if (isLoading) {
        return;
    }
    $('#loading').show();
    var params = decodeURIComponent(location.pathname);
    params = params.split('/')[1];
    params = params.split('.')[0];
    var sendStr = '';
    var count = 40;
    var pageIndex = params.split('_')[1] ? params.split('_')[1] : 1;
    sendStr = params = params.split('_')[0];
    $.ajax({
        url: '/list',
        data: {
            count: count,
            type: sendStr,
            pageindex: pageIndex
        }
    }).done(function (data) {
        if (data && data.length > 0) {
            let appendStr:any = [];
            for (let i = 0; i < data.length; i++) {
                if (i % 5 == 0) {
                    appendStr.push('<ul>');
                }
                appendStr.push('<li>');
                appendStr.push('<span>' + data[i].time + '</span>');
                if (data[i].type !== ''){
                    appendStr.push('[<a href="">' + data[i].type + '</a>]');
                };
                appendStr.push('<a href="' + data[i].url + '" title="' + data[i].title + '" target="_blank">' + data[i].title + '</a>');
                appendStr.push('</li>');
                if (i % 5 == 4 || ((i == data.length - 1) && i % 5 != 4)) {
                    appendStr.push('</ul>');
                }
            }

            appendStr = appendStr.join('');
            $('#artList').html(appendStr);
        }
    }).always(function(){
        isLoading = false;
        $('#loading').hide();
    })
}
$('#refbtn').on('click', function () {
    getListData();
});

$('#type_click').on('click', function () {
    clearTimeout(timer);
});

$('#type_auto').on('click', function () {
    if(timer){
        return
    }
    startTimer();
});

startTimer();




function addch(type) {
  let cTyles:any = location.pathname.split('/');
  if(cTyles[0] =='' && cTyles[1] == '' ){
      cTyles = 'default';
  }
  else if(cTyles[1].indexOf('_')>0){
      cTyles = cTyles[1].split('_')[0];
  }
  else {
      cTyles = cTyles[1].split('.')[0];
  }
  var url;
  var reg = new RegExp('(\\,|^)' + type + '(\\,|$)');
  if (!cTyles.match(reg)) {
      url = cTyles + "," + type + ".html";
  } else {
      url = cTyles.replace(reg, ",") + ".html";
  }
  url = url.replace("default,", "");
  url = url.replace(/,,/ig, ",");
  url = url.replace(",.html", ".html");
  url = (url.substring(0, 1) == ",") ? url.substring(1) : url;
  url = (url == ".html") ? "/" : url;
  window.location.href = url;
}

window.addch = addch

import cookie from "../modules/cookie"

$('#rolltip_close').on('click', function(){
    cookie.set('rolltip', '1', 3)
    $('#rolltip').remove()
    $('body').css({marginTop:43})
})

var rolltip_cookie = cookie.get('rolltip')
if(rolltip_cookie == '1'){
    $('#rolltip').remove()
    $('body').css({marginTop:43})
}